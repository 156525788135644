import * as React from "react"
import { Box, Grid, Tabs, Tab } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import Embed from "./Embed"
import logo from "../../assets/logo.png"

import "../../assets/styles.css"

const API_ENDPOINT = "https://azmx8lx8c7.execute-api.ap-southeast-1.amazonaws.com/v1"

class ReportTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invert : false,
      user: "",
      value: "armory",
      tabIndex: 0,
      loading: true,
      dashboardurls: {
        treasury_report: "",
        onchain_activity_report: "",
        armory_report: "",
        scholar_performance_report: "",
        audience_report: "",
        base_gfc_dashbord: ""      
      }
    }
    this.handleTabChange = this.handleTabChange.bind(this);
    this.getDashboardUrl = this.getDashboardUrl.bind(this);

  }
  componentDidMount() {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');    
    headers.append('Access-Control-Allow-Origin','*');
    headers.append('Origin','*');

    fetch( API_ENDPOINT +'/dashboardurls/', { 
          method: 'post', headers: headers, mode: 'cors',
          body: JSON.stringify(this.props.user)
        }).then(response => response.json()).then((data) => {
          // console.log(data.body)
          this.setState({
            user: this.props.user,
            dashboardurls: data.body.dashboard_urls,
            loading: false
          })
        }).catch((error) => {console.log(error)})   
  }

  componentWillUnmount() {

  }
  handleTabChange = (event, newTabIndex) => {
    // setTabIndex(newTabIndex);
    // console.log(event)
    if(event.target.id === "armory"){
      this.setState({
        tabIndex: 0,
        value: "armory"})
    }
    else if(event.target.id === "onchain"){
      this.setState({
        tabIndex: 1,
        value: "onchain"})
    }
    else if(event.target.id === "treasury"){
      this.setState({
        tabIndex: 2,
        value: "treasury"})
    }
    else if(event.target.id === "scholar"){
      this.setState({
        tabIndex: 3,
        value: "scholar"})
    }
    else if(event.target.id === "audience"){
      this.setState({
        tabIndex: 4,
        value: "audience"})
    }
  };
  
  getDashboardUrl = (dashboard) => {
    // console.log(this.state.dashboardurls);
    if(this.state.dashboardurls[dashboard].length > 0) { 
      // console.log(this.state.dashboardurls[dashboard])
      return this.state.dashboardurls[dashboard]; 
    }
    else { return "" }
  }
  
  render() {    
    return (      
      <div className="tabs_container">
        <div className="tab_header">
          <Grid container spacing={0} >
            <Grid item xs={4} >
              <div className="logo_container">
                <img className="logo" src={logo} alt="logo" />
              </div>
            </Grid>
            <Grid item xs={8} >
              <Grid container spacing={0} >
                <Grid item xs={8} />
                <Grid item xs={4} >
                  <div className="signout_button">
                    <button onClick={this.props.signOut}>Sign out {this.props.user.attributes.email}</button>
                  </div>
                </Grid>
                <Grid item xs={12} >
                  <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
                    <Tabs
                      value={this.state.value}
                      variant="fullWidth"
                      onChange={this.handleTabChange}
                      textColor="secondary"
                      indicatorColor="secondary">
                      <Tab value="armory" id="armory" label="Armory" />
                      <Tab value="onchain" id="onchain" label="On-Chain Activity" />
                      <Tab value="treasury" id="treasury" label="Treasury" />
                      <Tab value="scholar" id="scholar" label="Scholar Performance" />
                      <Tab value="audience" id="audience" label="Audience" />
                    </Tabs>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="tab_detail">
          <Grid container spacing={0} >
            {this.state.tabIndex === 0 && (
              <Grid item xs={12} >
                <Box>
                  {this.state.loading ? 
                  <div className="loading_spinner">
                    <CircularProgress sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: `auto`,
                      marginLeft: `auto`
                    }}/>
                  </div>
                  :                  
                  <div id="dashboardContainer" className="dashboardContainer">
                      <iframe
                          width="100%"
                          height="100%"
                          src={this.getDashboardUrl("armory-base-report")}>
                      </iframe>
                  </div>
                  }
                </Box>
              </Grid>
            )}
            {this.state.tabIndex === 1 && (
              <Grid item xs={12} >
                <Box>
                  {this.state.loading ? 
                    <div className="loading_spinner">
                      <CircularProgress sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: `auto`,
                        marginLeft: `auto`
                      }}/>
                    </div>
                    :                  
                    <div id="dashboardContainer" className="dashboardContainer">
                        <iframe
                            width="100%"
                            height="100%"
                            src={this.getDashboardUrl("onchain_activity_report")}>
                        </iframe>
                    </div>
                    }
                </Box>
              </Grid>
            )}
            {this.state.tabIndex === 2 && (
              <Grid item xs={12} >
                <Box>
                  {this.state.loading ? 
                      <div className="loading_spinner">
                        <CircularProgress sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: `auto`,
                          marginLeft: `auto`
                        }}/>
                      </div>
                      :                  
                      <div id="dashboardContainer" className="dashboardContainer">
                          <iframe
                              width="100%"
                              height="100%"
                              src={this.getDashboardUrl("treasury_report")}>
                          </iframe>
                      </div>
                      }

                </Box>
              </Grid>
            )}
            {this.state.tabIndex === 3 && (
              <Grid item xs={12} >
                <Box>
                  {this.state.loading ? 
                      <div className="loading_spinner">
                        <CircularProgress sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: `auto`,
                          marginLeft: `auto`
                        }}/>
                      </div>
                      :                  
                      <div id="dashboardContainer" className="dashboardContainer">
                          <iframe
                              width="100%"
                              height="100%"
                              src={this.getDashboardUrl("scholar_performance_report")}>
                          </iframe>
                      </div>
                      }

                </Box>
              </Grid>
            )}
            {this.state.tabIndex === 4 && (
              <Grid item xs={12} >
                <Box>
                  {this.state.loading ? 
                      <div className="loading_spinner">
                        <CircularProgress sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: `auto`,
                          marginLeft: `auto`
                        }}/>
                      </div>
                      :                  
                      <div id="dashboardContainer" className="dashboardContainer">
                          <iframe
                              width="100%"
                              height="100%"
                              src={this.getDashboardUrl("audience_report")}>
                          </iframe>
                      </div>
                      }

                </Box>
              </Grid>
            )}
            
          </Grid>
        </div>
      </div>
    
    )
  }
}

export default ReportTabs
