import { Amplify } from "aws-amplify";

import { withAuthenticator } from "@aws-amplify/ui-react";
import { Header } from "./components/auth/Header";
import { Footer } from "./components/auth/Footer";
import { SignInHeader } from "./components/auth/SignInHeader";
import { SignInFooter } from "./components/auth/SignInFooter";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Layout from './components/general/layout'
import ReportTabs from './components/reports/report_tabs'
import Embed from './components/reports/Embed'
import "./assets/styles.css";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

export function App({ signOut, user }) {
  // console.log(user)

  return (
    <main>
      
      <Container maxWidth="100vw">        
        <ReportTabs user={user} signOut={signOut} />;
      </Container>
      </main>
  );
}

export default withAuthenticator(App, {
  components: {
    Header,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },
    Footer
  }
});

// 'Content-Type,Access-Control-Allow-Origin,Origin,X-Requested-With,Accept,Authorization,X-Amz-Date,X-Api-Key,X-Amz-Security-Token'