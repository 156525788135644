import React from 'react';
import { API, Auth } from 'aws-amplify';
import CircularProgress from '@mui/material/CircularProgress';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import "../../assets/styles.css"


const useStyles = theme => ({
  loading: {
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginTop: theme.spacing(4),
  },
});

class Embed extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loader: true
        };
    }
    
    componentDidMount() {
        // this.getQuickSightDashboardEmbedURL();
    }
    
    getQuickSightDashboardEmbedURL = async () => {
        // const data = await Auth.currentSession();
        // const jwtToken = data.idToken.jwtToken;
        // const payloadSub = data.idToken.payload.sub;
        // const email = data.idToken.payload.email;
        
        // const params = { 
        //     headers: {},
        //     response: true,
        //     queryStringParameters: {
        //         jwtToken: jwtToken,
        //         payloadSub: payloadSub,
        //         email: email
        //     }
        // }
        // const quicksight = await API.get('pdadevrptdashbaord', '/embedurl', params);
        // console.log(quicksight);
        const containerDiv = document.getElementById("dashboardContainer");
        
        const options = {
            url: 'https://ap-southeast-1.quicksight.aws.amazon.com/embed/81299bd764b24b88b93d85714866e7f8/dashboards/f848ae19-34b5-487a-afce-5fd1d26a4b01',
            container: containerDiv,
            parameters: {
                country: "Singapore"
            },
            scrolling: "no",
            height: "90vh",
            width: "100vw",
            footerPaddingEnabled: true,
        };
        const dashboard = QuickSightEmbedding.embedDashboard(options);
        console.log(dashboard)
        this.setState({ loader: false });
    };
    
    render() {
        // const { classes } = this.props;
        return (
            <div>
                {/* { this.state.loader && (
                    // <div className={classes.loading}> <CircularProgress /> </div>
                    <div> <CircularProgress /> </div>
                )} */}
                
            </div>
        );
    }
}

export default Embed;


